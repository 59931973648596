import GENERIC_BANNER from "../../assets/listing-page-generic.jpg";
import ALIBAUG_BANNER from "../../assets/listing-page-alibaug.jpg";
import BALI_BANNER from "../../assets/listing-page-bali.jpg";
import COONOOR_BANNER from "../../assets/listing-page-coonoor.jpg";
import GOA_BANNER from "../../assets/listing-page-goa.jpg";
import KARJAT_BANNER from "../../assets/listing-page-karjat.jpg";
// import OOTY_BANNER from "../../../assets/listing-page-ooty.jpg";
import RISHIKESH_BANNER from "../../assets/listing-page-rishikesh.jpg";
import KOH_SAMUI_BANNER from "../../assets/listing-page-koh-samui.jpg";
import LONAVALA_BANNER from "../../assets/listing-page-lonavala.jpg";
import PHUKET_BANNER from "../../assets/listing-page-phuket.jpg";
import MAHABALESHWAR_BANNER from "../../assets/listing-page-mahabaleshwar.jpg";
import MUSSOORIE_BANNER from "../../assets/listing-page-mussoorie.jpg";
//import BHIMTAL_BANNER from "../../assets/listing-page-bhimtal.jpg";
import SRINAGAR_BANNER from "../../assets/listing-page-srinagar.jpg";
import JIM_CORBETT_BANNER from "../../assets/listing-page-jim-corbett.jpg";
import JAIPUR_BANNER from "../../assets/listing-page-jaipur.jpg";
import SHIMLA_BANNER from "../../assets/listing-page-shimla.jpg";
import KASAULI_BANNER from "../../assets/listing-page-kasauli.jpg";
// import SRI_LANKA_BANNER from "../../assets/listing-page-sri-lanka.jpg";
import MALDIVES_BANNER from "../../assets/listing-page-maldives.jpg";
import COORG_BANNER from "../../assets/listing-page-coorg.jpg";

export const LOCATIONS_BANNER_MAP: LocationBannerDetails = {
  goa: {
    image: GOA_BANNER,
    title: "Finest Luxury Villas in Goa with Lohono Stays.",
    subtitle: "Experience Unmatched Comfort in the Heart of Goa.",
    annotation: "domestic",
    footerSubtitle:
      "Your search for the finest luxury villas in Goa ends here. At Lohono Stays, we offer an exquisite selection of the best luxury villas in Goa, ensuring a lavish and unforgettable escape. Whether you're seeking private pool villas to stay in Goa, a holiday villa for relaxation, or a private villa for events and parties to celebrate special occasions, our accommodations provide unmatched comfort and elegance. Our pet-friendly villas ensure your furry friends can join in on the fun, making it a family-friendly destination. With villas boasting private pools and located in prime areas such as North Goa, Anjuna, Siolim, Parra, Baga, and Candolim, your Goan experience will be nothing short of extraordinary. Conveniently book your perfect villa in Goa with Lohono Stays and immerse yourself in the beauty and luxury of the coastal state.",
    seoTags: {
      title:
        "Luxury Villas in Goa - Lohono Stays | Private Pool, Pet-Friendly Accommodations",
      description:
        "Discover the finest luxury villas in Goa with Lohono Stays. From private pool villas in prime areas like Anjuna, Siolim, and Baga to pet-friendly options, enjoy an unmatched Goan experience. Book your lavish escape today.",
      canonical: "https://www.lohono.com/locations/india-goa?destination=goa",
      keywords: "",
    },
  },
  lonavala: {
    image: LONAVALA_BANNER,
    title: "Luxurious Villas in Lonavala and Khandala by Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    annotation: "domestic",
    footerSubtitle:
      "Discover the epitome of luxury with our curated selection of the best villas in Lonavala and Khandala, ideal for unforgettable getaways near Mumbai. Whether you're in search of the private villas in Khandala, the perfect private villa for a party in Lonavala, or a tranquil private pool villa near Mumbai, our accommodations offer unmatched comfort and elegance. Explore options that include villas facing the Pawna Lake or pet-friendly villas suitable for the whole family. With our selection of the best villas to stay in Lonavala and Khandala, your getaways near Mumbai will be an experience to remember.Choose Lohono Stays for a luxurious escape to the scenic beauty of Lonavala and Khandala, for your next short trip from Mumbai, and create cherished memories that will last a lifetime.",
    seoTags: {
      title: "Best Luxury Villas in Lonavala and Khandala | Lohono Stays",
      description:
        "Discover luxury with the finest villas in Lonavala and Khandala. From private pool villas to pet-friendly accommodations, choose Lohono Stays for unforgettable getaways near Mumbai.",
      canonical:
        "https://www.lohono.com/locations/india-lonavala?destination=lonavala",
      keywords: "",
    },
  },
  khandala: {
    image: LONAVALA_BANNER,
    title: "Luxurious Villas in Lonavala and Khandala by Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    annotation: "domestic",
    footerSubtitle:
      "Discover the epitome of luxury with our curated selection of the best villas in Lonavala and Khandala, ideal for unforgettable getaways near Mumbai. Whether you're in search of the private villas in Khandala, the perfect private villa for a party in Lonavala, or a tranquil private pool villa near Mumbai, our accommodations offer unmatched comfort and elegance. Explore options that include villas facing the Pawna Lake or pet-friendly villas suitable for the whole family. With our selection of the best villas to stay in Lonavala and Khandala, your getaways near Mumbai will be an experience to remember.Choose Lohono Stays for a luxurious escape to the scenic beauty of Lonavala and Khandala, for your next short trip from Mumbai, and create cherished memories that will last a lifetime.",
    seoTags: {
      title: "Best Luxury Villas in Lonavala and Khandala | Lohono Stays",
      description:
        "Discover luxury with the finest villas in Lonavala and Khandala. From private pool villas to pet-friendly accommodations, choose Lohono Stays for unforgettable getaways near Mumbai.",
      canonical:
        "https://www.lohono.com/locations/india-lonavala?destination=khandala",
      keywords: "",
    },
  },
  alibaug: {
    image: ALIBAUG_BANNER,
    title: "Lohono Stays: Your Luxury Villa Experience in Alibaug Awaits.",
    subtitle:
      "Experience Exclusivity, Comfort, and Luxury with Lohono Stays in Alibaug.",
    annotation: "domestic",
    footerSubtitle:
      "Looking for villas near Mumbai? Dive into luxury with our villas in Alibaug which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best luxury villas with private swimming pools, private lawns and premium concierge services. You can stay at one of our beachside villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Alibaug villas are accessible from the jetty (RORO or M2M ferries) or are a short drive away from Mumbai. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect Alibaug villa stays now! You can also explore our other luxury homestays across 20+ locations in India and SouthEast Asia.",
    seoTags: {
      title:
        "Luxury Villas in Alibaug: Beachside & Pet-Friendly Options Near Mumbai | Lohono Stays",
      description:
        "Explore luxury villas in Alibaug with Lohono Stays. Beachside, pet-friendly getaways near Mumbai with premium amenities.",
      canonical:
        "https://www.lohono.com/locations/india-alibaug?destination=alibaug",
      keywords: "",
    },
  },
  karjat: {
    image: KARJAT_BANNER,
    title:
      "Handpicked Luxury Villas in Karjat: Your Perfect Getaway Near Mumbai.",
    subtitle:
      "Elegance, Comfort, and Opulence: Experience Karjat Like Never Before with Lohono Stays.",
    annotation: "domestic",
    footerSubtitle:
      "Your search for a luxury villa in Karjat ends here. Discover the epitome of opulence with our handpicked selection of the best luxury villas in Karjat, offering the perfect getaway near Mumbai. Whether you're searching for the best pool villas in Karjat, comfortable private villa for a party, or private holiday homes with river-access near Mumbai, our accommodations provide unmatched comfort and elegance. Explore options such as a holiday villa or pet-friendly villas, ideal for families. With a range of villas for family and kid-friendly villas in Karjat, your escape near Mumbai will be a memorable experience. Conveniently book your perfect villa online and enjoy the beauty of this stunning destination. Choose Lohono Stays for your luxury villa in Karjat, ensuring a lavish and memorable getaway near Mumbai.",
    seoTags: {
      title:
        "Luxury Villas in Karjat - Premium Pool & Pet-Friendly Accommodations Near Mumbai | Lohono Stays",
      description:
        "Discover handpicked luxury villas in Karjat, perfect for getaways near Mumbai. Choose from pool villas, private party homes, river-access holiday homes, and family-friendly villas. Book online with Lohono Stays for an unforgettable experience.",
      canonical:
        "https://www.lohono.com/locations/india-karjat?destination=karjat",
      keywords: "",
    },
  },
  coonoor: {
    image: COONOOR_BANNER,
    title:
      "Experience Coonoor Like Never Before: Luxury Villas Tailored For You",
    subtitle: "Elevate Your Stay: Where Coonoor's Serenity Meets Luxury.",
    annotation: "domestic",
    footerSubtitle:
      "Embark on a journey into luxury with our thoughtfully selected range of premium villas in Coonoor, offering the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa, an elegant private villa for your special events, or a farmhouse stay near Bangalore, our Coonoor accommodations offer unparalleled comfort and style. Our villas in Coonoor cater to your every need, whether you're seeking a peaceful getaway or a private villa with a pool. With pet-friendly options and villas near Bangalore, your Coonoor experience will be nothing short of extraordinary. Plan your family getaways and book your dream villa, adding a touch of flair to your relaxing and luxurious stay in the serene hills of Coonoor.",
    seoTags: {
      title:
        "Premium Villas in Coonoor - Luxurious Hillside Accommodations Near Bangalore",
      description:
        "Discover the epitome of luxury with our handpicked villas in Coonoor. From idyllic holiday retreats to exquisite event spaces, enjoy unmatched comfort amidst the serene Coonoor hills, conveniently near Bangalore.",
      canonical:
        "https://www.lohono.com/locations/india-coonoor?destination=coonoor",
      keywords: "",
    },
  },
  coorg: {
    image: COORG_BANNER,
    title: "Luxury Villas in Coorg by Lohono",
    subtitle: "Experience Extraordinary Comfort & Style in Coorg.",
    annotation: "domestic",
    footerSubtitle:
      "Experience a world of luxury with our thoughtfully selected range of premium villas in Coorg, including the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa or an elegant private villa for your special events, our Coorg accommodations offer unparalleled comfort and style. Our villas in Coorg cater to your every need, whether you're seeking a peaceful getaway, a farm stay near Bangalore, or a private villa with a pool. With pet-friendly options and villas near Bangalore, your Coorg experience will be nothing short of extraordinary. Plan your family villa getaway in Coorg, and immerse yourself in the beauty and luxury of Lohono.",
    seoTags: {
      title:
        "Premium Villas in Coorg - Experience Unmatched Luxury with Lohono",
      description:
        "Discover luxury at its best with Lohono's range of premium villas in Coorg. Whether you seek an unforgettable holiday, a serene farm stay, or a lavish private villa with a pool, find your perfect retreat here. Pet-friendly options available.",
      canonical:
        "https://www.lohono.com/locations/india-coorg?destination=coorg",
      keywords: "",
    },
  },
  mahabaleshwar: {
    image: MAHABALESHWAR_BANNER,
    title: "Exquisite Luxury Villas in Mahabaleshwar",
    subtitle: "Immerse in Unmatched Comfort & Elegance with Lohono.",
    annotation: "domestic",
    footerSubtitle:
      "Looking for the finest luxury villas in Mahabaleshwar for your next family trip from Mumbai? At Lohono Stays, we offer an exquisite selection of the best luxury villas in Mahabaleshwar, ensuring a lavish and unforgettable escape. Whether you're seeking the best villas to stay in Mahabaleshwar, a holiday villa for relaxation, or a private villa for a party in Mahabaleshwar, our accommodations provide unmatched comfort and elegance. Our pet-friendly villas ensure your furry friends can join in on the fun, making it a family-friendly destination. With villas boasting private pools, your Mahabaleshwar experience will be nothing short of extraordinary. Conveniently book your perfect villa in Mahabaleshwar, and immerse yourself in the beauty and luxury of Lohono, ideal for getaways near Mumbai.",
    seoTags: {
      title:
        "Lohono Stays: Luxury Villas in Mahabaleshwar | Pet-Friendly Accommodations",
      description:
        "Discover the finest selection of luxury villas in Mahabaleshwar with Lohono Stays. Ideal for family trips, relaxation, or parties. Featuring private pools and pet-friendly options, our villas offer unmatched comfort, making it the perfect getaway near Mumbai.",
      canonical:
        "https://www.lohono.com/locations/india-mahabaleshwar?destination=mahabaleshwar",
      keywords: "",
    },
  },
  mussoorie: {
    image: MUSSOORIE_BANNER,
    title:
      "Luxury Villas in Mussoorie and Dehradun: A Pinnacle of Comfort and Scenic Beauty",
    subtitle:
      "Your Perfect Hill Station Escape: Unique & Luxurious Mountain Stays.",
    annotation: "domestic",
    footerSubtitle:
      "Discover the pinnacle of luxury with our handpicked selection of the best luxury villas in Mussoorie and Dehradun, making them the best getaways from Delhi. With private villas in Mussoorie or Dehradun, you can conveniently book your ideal stay– whether it's a luxury cottage, villa with a private pool and amenities, or a holiday villa facing the lush valleys. Our pet-friendly villas ensure your furry companions can join in on the fun as well! And if you're looking to celebrate, we offer private villas for party and event purposes in Mussoorie and Dehradun too. Our luxurious and unique mountain stays in Mussoorie and Dehradun provide the perfect escape for your family getaway. Choose from the best villas to rent and make the most of your time in these picturesque hill stations.",
    seoTags: {
      title:
        "Best Luxury Villas in Mussoorie and Dehradun: Private Getaways from Delhi",
      description:
        "Discover top luxury villas in Mussoorie and Dehradun, perfect for getaways from Delhi. From cottages to pool villas with scenic views, our hand picked selections ensure a unique mountain stay. Pet-friendly options available!",
      canonical:
        "https://www.lohono.com/locations/india-mussoorie?destination=mussoorie",
      keywords: "",
    },
  },
  dehradun: {
    image: MUSSOORIE_BANNER,
    title: "Villa Living Meets The Hills, At Dehradun",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    annotation: "domestic",
    footerSubtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller Come Lohono with us at our villas in Dehradun, perched on lush mountains nestled amidst breathtaking landscapes. Villas located at the heart of this breathtaking town, book your luxury villa stay near Dehradun’s tourist spots. In case you wish to day-trip for a day or two, our villas in Dehradun are also a stone’s throw away from Mussoorie, Kanatal and Rishikesh. From villas with private pools blending into nature to 5 star amenities like Wi-Fi, housekeeping and villas in Dehradun with complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Uttarakhand with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Dehradun",
    seoTags: {
      title: "Villa Living Meets The Hills, At Dehradun",
      description:
        "Come Lohono with us at our villas in Dehradun, perched on lush mountains nestled amidst breathtaking landscapes. Villas located at the heart of this breathtaking town, book your luxury villa stay near Dehradun’s tourist spots. In case you wish to day-trip for a day or two, our villas in Dehradun are also a stone’s throw away from Mussoorie, Kanatal and Rishikesh. From villas with private pools blending into nature to 5 star amenities like Wi-Fi, housekeeping and villas in Dehradun with complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Uttarakhand with our delectable local lip-smacking preparations in-house.",
      canonical:
        "https://www.lohono.com/locations/india-mussoorie?destination=dehradun",
      keywords: "",
    },
  },
  // bhimtal: {
  //   image: BHIMTAL_BANNER,
  //   title:
  //     "Uncover Bhimtal's Luxury: Premium Lakeside Villas Tailored for Your Comfort.",
  //   subtitle:
  //     "Bhimtal Bliss: Where Serenity, Luxury, and Lakeside Living Intersect.",
  //   annotation: "domestic",
  //   footerSubtitle:
  //     "Delve into a world of luxury with our thoughtfully selected range of premium villas in Bhimtal, offering the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa facing Bhimtal lake, an elegant private villa for your special events, or a peaceful cottage stay, our accommodations provide unparalleled comfort and style. Our villas in Bhimtal cater to your every need, whether you're seeking a tranquil getaway or a private villa with a jacuzzi. With pet-friendly options and villas near Nainital, your Bhimtal experience will be nothing short of extraordinary. Plan your family getaways in Bhimtal with Lohono and book your villa stay for a relaxing and luxurious stay in the heart of this serene lakeside town.",
  //   seoTags: {
  //     title:
  //       "Luxury Villas in Bhimtal - Lakeside Premium Accommodations Near Bhimtal",
  //     description:
  //       "Step into a world of luxury with our exquisite villas in Bhimtal. Nestled by the serene Bhimtal Lake and close to Nainital, experience ultimate comfort, style, and tranquil getaways, complete with options like private jacuzzis.",
  //     canonical:
  //       "https://www.lohono.com/locations/india-bhimtal?destination=bhimtal",
  //     keywords: "",
  //   },
  // },
  srinagar: {
    image: SRINAGAR_BANNER,
    title:
      "Opulent Villa Stays in Srinagar and Pahalgam: Dive into J&K's Luxury.",
    subtitle:
      "Heart of Paradise Awaits: Experience Unmatched Luxury in Srinagar & Pahalgam.",
    annotation: "domestic",
    footerSubtitle:
      "Discover a world of opulence with our carefully curated selection of premium villas in Srinagar and Pahalgam, featuring the best luxury villas for an unforgettable stay for your next villa getaway to Jammu and Kashmir. Whether you're in search of the perfect holiday villa, a mountain-style cottage stay, or a charming farmhouse stay in Pahalgam, our accommodations offer unparalleled comfort and style. Our villas in Srinagar and Pahalgam cater to your every need, book yourself a peaceful getaway or a private villa for your parties or intimate events. With pet-friendly options and ample villas near Srinagar and Pahalgam, your experience in the heart of paradise will be nothing short of extraordinary.",
    seoTags: {
      title:
        "Luxury Villas in Srinagar & Pahalgam - Premium Accommodations in Jammu and Kashmir",
      description:
        "Indulge in the lavishness of our selected villas in Srinagar and Pahalgam. From holiday retreats to mountain-style cottages, experience ultimate comfort and style in the paradise of Jammu and Kashmir. Ideal for Delhi-based getaways.",
      canonical:
        "https://www.lohono.com/locations/india-srinagar?destination=srinagar",
      keywords: "",
    },
  },
  pahalgam: {
    image: SRINAGAR_BANNER,
    title:
      "Opulent Villa Stays in Pahalgam and Srinagar: Dive into J&K's Luxury.",
    subtitle: "Heart of Paradise Awaits: Experience Unmatched Luxury in Pahalgam & Srinagar.",
    annotation: "domestic",
    footerSubtitle: "Discover a world of opulence with our carefully curated selection of premium villas in Srinagar and Pahalgam, featuring the best luxury villas for an unforgettable stay for your next villa getaway to Jammu and Kashmir. Whether you're in search of the perfect holiday villa, a mountain-style cottage stay, or a charming farmhouse stay in Pahalgam, our accommodations offer unparalleled comfort and style. Our villas in Srinagar and Pahalgam cater to your every need, book yourself a peaceful getaway or a private villa for your parties or intimate events. With pet-friendly options and ample villas near Srinagar and Pahalgam, your experience in the heart of paradise will be nothing short of extraordinary.",
    seoTags: {
      title:
        "Luxury Villas in Srinagar & Pahalgam - Premium Accommodations in Jammu and Kashmir",
      description:
        "Indulge in the lavishness of our selected villas in Srinagar and Pahalgam. From holiday retreats to mountain-style cottages, experience ultimate comfort and style in the paradise of Jammu and Kashmir. Ideal for Delhi-based getaways.",
      canonical:
        "https://www.lohono.com/locations/india-srinagar?destination=pahalgam",
      keywords: "",
    },
  },
  kasauli: {
    image: KASAULI_BANNER,
    title: "Experience Unparalleled Luxury: Premium Villas in Kasauli",
    subtitle:
      "Immerse in Lohono's Beauty and Luxury. Kasauli's Finest Villas Await.",
    annotation: "domestic",
    footerSubtitle:
      "Embark on a journey into luxury with our carefully curated selection of premium villas in Kasauli, which include the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa, an elegant private villa for your special events, or a farmhouse stay near Delhi, our Kasauli accommodations offer unparalleled comfort and style. Our villas in Kasauli cater to your every need, whether you're seeking a peaceful getaway or a private villa with a pool. With pet-friendly options and villas near Delhi, your Kasauli experience will be nothing short of extraordinary. Book your villa getaway in Kasauli, and immerse yourself in the beauty and luxury of Lohono, ideal for short trips from Delhi.",
    seoTags: {
      title: "Premium Villas in Kasauli - Luxury Accommodations Near Delhi",
      description:
        "Discover luxury with our handpicked selection of villas in Kasauli. Whether you're planning a holiday, an event, or seeking a tranquil escape, our accommodations promise comfort, style, and convenience, especially for those traveling from Delhi.",
      canonical:
        "https://www.lohono.com/locations/india-kasauli?destination=kasauli",
      keywords: "",
    },
  },
  // "india-ooty": OOTY_BANNER,
  rishikesh: {
    image: RISHIKESH_BANNER,
    title: "Discover Rishikesh in Style: Handpicked Luxury Villas by Lohono",
    subtitle: "Rishikesh Redefined: Where Luxury Meets the River's Edge.",
    annotation: "domestic",
    footerSubtitle:
      "Explore a world of luxury with our handpicked selection of premium villas in Rishikesh, including the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa, an elegant private villa for your special events, or a river-facing stay near Delhi, our Rishikesh accommodations offer unparalleled comfort and style. Our villas in Rishikesh cater to your every need, whether you're seeking a peaceful getaway or a private villa with a pool. With pet-friendly options and villas near Delhi, your Rishikesh experience will be nothing short of extraordinary. Conveniently book your perfect villa in Rishikesh, and immerse yourself in the beauty and luxury of Lohono, ideal for getaways near Delhi.",
    seoTags: {
      title:
        "Premium Villas in Rishikesh - Luxury River-facing Accommodations Near Delhi",
      description:
        "Dive into luxury with our exclusive selection of villas in Rishikesh. From perfect holiday homes to elegant event spaces, experience unmatched comfort and style. Especially ideal for Delhi travelers seeking river views or pool amenities.",
      canonical:
        "https://www.lohono.com/locations/india-rishikesh?destination=rishikesh",
      keywords: "",
    },
  },
  jimcorbett: {
    image: JIM_CORBETT_BANNER,
    title: "Exclusive Private Pool Villas and Resorts in Jim Corbett.",
    subtitle: "Experience Luxury in the Heart of the Wilderness.",
    annotation: "domestic",
    footerSubtitle:
      "Discover the epitome of luxury with our exclusive private pool villas and luxury resorts in Jim Corbett, offering an exceptional getaway experience. Whether you're planning a unique New Year's celebration with our new year villa rental options, searching for the best place to stay in Jim Corbett, or seeking a thrilling camp stay experience, our villas, resorts, and stays provide unmatched comfort and exclusivity. Explore diverse accommodation options, including jungle lodges, homestays, and accommodations in the jungle that provide access to Jim Corbett's captivating wildlife and adventurous activities. With pet-friendly resorts and a range of adventure activities in the park, you'll create lasting memories in the heart of Jim Corbett's natural wonders. Choose Lohono Stays for a luxurious escape, 5 hours for Delhi, in Jim Corbett's stunning wilderness.",
    seoTags: {
      title:
        "Luxury Private Pool Villas & Resorts in Jim Corbett | Lohono Stays",
      description:
        "Discover luxury with our exclusive private pool villas, resorts, and unique stays in Jim Corbett. From thrilling jungle lodges to pet-friendly resorts, experience the heart of Jim Corbett's natural wonders, just 5 hours from Delhi.",
      canonical:
        "https://www.lohono.com/locations/india-jimcorbett?destination=jimcorbett",
      keywords: "",
    },
  },
  jaipur: {
    image: JAIPUR_BANNER,
    title: "Experience Royal Luxury with Lohono Villas in Jaipur",
    subtitle: "Dive into Jaipur's Royal Comfort with Lohono Stays",
    annotation: "domestic",
    footerSubtitle:
      "Looking for villas in Jaipur? Dive into luxury with our villas in Jaipur which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best luxury villas and traditional palace living with private swimming pools, private lawns and premium concierge services. You can stay at one of our Jaipur palace villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Our Jaipur villas are a short drive away from Delhi and are perfect for weekend getaways or even pre-wedding photoshoots. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect royal life through villa stays now! You can also explore our other luxury homestays across 20+ locations in India and South East Asia.",
    seoTags: {
      title:
        "Luxury Villas in Jaipur | Lohono Stays - Royal Living & Exclusive Services",
      description:
        "Discover luxury villas in Jaipur with Lohono Stays. Enjoy palace living, private pools, and premium services. A short drive from Delhi, ideal for weekends and special occasions. Experience royal comfort.",
      canonical:
        "https://www.lohono.com/locations/india-jaipur?destination=jaipur",
      keywords: "",
    },
  },
  shimla: {
    image: SHIMLA_BANNER,
    title: "Dive into the Luxury of Lohono's Shimla Villas.",
    subtitle: "Discover Shimla's Hidden Luxury with Lohono Stays.",
    annotation: "domestic",
    footerSubtitle:
      "Looking for villas in Shimla? Dive into luxury with our villas in Shimla which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best villas with private lawns and premium concierge services. You can stay at one of our hilltop villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Our luxury homestays are a drive away from Delhi and are perfect for weekend getaways, yoga, meditation or wellness retreats. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect hidden Himalayan treasure through trusted and best rated luxury stays now! You can also explore our other luxury homestays across 20+ locations in India and South East Asia.",
    seoTags: {
      title:
        "Luxury Villas in Shimla | Lohono Stays - Experience Himalayan Bliss",
      description:
        "Lohono's luxury villas in Shimla: hilltop stays, pet-friendly options, and weekend retreats. Experience Himalayan luxury a drive from Delhi.",
      canonical:
        "https://www.lohono.com/locations/india-shimla?destination=shimla",
      keywords: "",
    },
  },
  kohsamui: {
    image: KOH_SAMUI_BANNER,
    title: "Beach Life At It’s Finest At Lohono’s Koh Samui",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    annotation: "international",
    footerSubtitle:
      "Experience Thailand’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Koh Samui, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Koh Samui with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Koh Samui.",
    seoTags: {
      title:
        "Luxury Villas in Koh Samui - Beach Life At It’s Finest At Lohono stays",
      description:
        "Experience Thailand’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Koh Samui, there’s a stay for every mood at Lohono.",
      canonical:
        "https://www.lohono.com/locations/thailand-koh-samui?destination=kohsamui",
      keywords: "",
    },
  },
  phuket: {
    image: PHUKET_BANNER,
    title: "Beach Life At It’s Finest At Lohono’s Phuket",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    annotation: "international",
    footerSubtitle:
      "Experience Thailand’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Phuket, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Phuket with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Phuket.",
    seoTags: {
      title: "Luxury villas in Phuket",
      description:
        "Experience Thailand’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Phuket, there’s a stay for every mood at Lohono.",
      canonical:
        "https://www.lohono.com/locations/thailand-phuket?destination=phuket",
      keywords: "",
    },
  },
  // pench: {
  //   image: GENERIC_BANNER,
  //   title: "Live the Safari life at Lohono’s Pench",
  //   subtitle:
  //     "Perfectly crafted luxury villas and experiences for the discerning traveller",
  //   annotation: "domestic",
  //   footerSubtitle:
  //     "Immerse yourself in the poetry of nature and Safari stories at our handpicked luxury villas in Pench. From farm-to-table food experiences to private safari adventures, come Lohono with us at our villas in Pench to experience the Safari life to the fullest. Our villas in Pench feature private pools, complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Madhya Pradesh with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Pench.",
  //   seoTags: {
  //     title: "Luxury villas in Pench - Live the Safari life",
  //     description:
  //       "Immerse yourself in the poetry of nature and Safari stories at our handpicked luxury villas in Pench. From farm-to-table food experiences to private safari adventures, come Lohono with us at our villas in Pench to experience the Safari life to the fullest. Our villas in Pench feature private pools, complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Madhya Pradesh with our delectable local lip-smacking preparations in-house.",
  //     canonical:
  //       "https://www.lohono.com/locations/india-pench?destination=pench",
  //     keywords: "",
  //   },
  // },
  bali: {
    image: BALI_BANNER,
    title: "Bali At It’s Finest, With Lohono",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    annotation: "international",
    footerSubtitle:
      "Experience Bali’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays in Ubud, to villas by the beach in Seminyak, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Bali with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Bali.",
    seoTags: {
      title: "Bali At It’s Finest, With Lohono Stays",
      description:
        "Experience Bali’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays in Ubud, to villas by the beach in Seminyak, there’s a stay for every mood at Lohono.",
      canonical:
        "https://www.lohono.com/locations/indonesia-bali?destination=bali",
      keywords: "",
    },
  },
  // srilanka: {
  //   image: SRI_LANKA_BANNER,
  //   title: "Tropical Living Meets Luxury, At Lohono’s Sri Lanka",
  //   subtitle:
  //     "Perfectly crafted luxury villas and experiences for the discerning traveller",
  //   annotation: "international",
  //   footerSubtitle:
  //     "Experience Sri Lanka’s coastal charm at its most exquisite with Lohono. Nestled in the island's most sought-after beach destinations, our soul-soothing villas invite you to indulge in a retreat where luxury is woven into every moment. The gentle sea breeze, the aromatic spices, the rich cultural tapestry—each experience tells a tale of its own. Our villa concierge curates a range of oceanic adventures, from snorkelling and whale watching to deep-sea fishing and sailing, ensuring your stay is nothing short of extraordinary. Whether you seek tropical hillside retreats or beachfront villas in Kosgoda, Bentota, Lohono has a stay in Sri Lanka for every mood. Revel in villas with private pools in Sri Lanka seamlessly integrated into nature, complete with 5-star amenities like high-speed Wi-Fi, attentive housekeeping, and complimentary breakfast. Delight in the authentic coastal flavours crafted by our in-house chefs, delivering a culinary experience that captures the essence of Sri Lanka. Embrace nature’s beauty and our unmatched hospitality—there’s nowhere you’d rather be than at Lohono, offering the finest villas in Sri Lanka.",
  //   seoTags: {
  //     title:
  //       "Luxury Villas in Sri Lanka - Tropical Living Meets Luxury, At Lohono stays",
  //     description:
  //       "Experience Sri Lanka’s coastal charm at its most exquisite with Lohono. Nestled in the island’s most sought-after beach destinations, our soul-soothing villas invite you to indulge in a retreat where luxury is woven into every moment. The gentle sea breeze, the aromatic spices, the rich cultural tapestry—each experience tells a tale of its own. Our villa concierge curates a range of oceanic adventures, from snorkelling and whale watching to deep-sea fishing and sailing, ensuring your stay is nothing short of extraordinary. Whether you seek tropical hillside retreats or beachfront villas in Kosgoda, Bentota, Lohono has a stay in Sri Lanka for every mood.",
  //     canonical:
  //       "https://www.lohono.com/locations/srilanka-sri-lanka?destination=srilanka",
  //     keywords: "",
  //   },
  // },
  maldives: {
    image: MALDIVES_BANNER,
    title: "Maldives At It’s Finest, With Lohono",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    annotation: "international",
    footerSubtitle:
      "Experience Maldives’ coastal living at Lohono Stays. With soul-reviving villas in South-east Asia’s best and cleanest beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Maldives, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Male with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Maldives.",
    seoTags: {
      title: "Maldives At It’s Finest, With Lohono stays",
      description:
        "Experience Maldives’ coastal living at Lohono Stays. With soul-reviving villas in South-east Asia’s best and cleanest beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono.",
      canonical:
        "https://www.lohono.com/locations/maldives-maldives?destination=maldives",
      keywords: "",
    },
  },
  gurgaon: {
    image: PHUKET_BANNER,
    title: "Premium Villas in Gurgaon: Your Perfect Escape Near Delhi",
    subtitle: "Immerse in Unmatched Comfort & Style",
    annotation: "domestic",
    footerSubtitle:
      "Discover a world of luxury with our carefully selected range of premium villas in Gurgaon, including the best luxury villas for your stay. Whether you're looking for the perfect holiday villa or an elegant private villa for your special events, our Gurgaon accommodations offer unmatched comfort and style. Our villas in Gurgaon cater to your every need, whether you're seeking a peaceful getaway or an exciting celebration. Choose from an array of pet-friendly options and villas with private pools for a memorable stay in Gurgaon, just a stone's throw away from Delhi. Whether you're planning a farm stay near Delhi or a peaceful getaway, our villas in Gurgaon offer the perfect escape. Conveniently book your perfect villa in Gurgaon, and immerse yourself in the beauty and luxury of Lohono, ideal for getaways near Delhi.",
    seoTags: {
      title: "Luxury Villas in Gurgaon: Premium Accommodations Near Delhi",
      description:
        "Discover a world of luxury with our handpicked range of premium villas in Gurgaon. Perfect for holidays, special events, or a peaceful getaway. Pet-friendly options and private pools available. A stone's throw from Delhi.",
      canonical:
        "https://www.lohono.com/locations/india-gurgaon?destination=gurgaon",
      keywords: "",
    },
  },
  // udaipur: {
  //   image: GENERIC_BANNER,
  //   title: "Experience Royalty, the Lohono Way at Udaipur",
  //   subtitle:
  //     "Perfectly crafted luxury villas and experiences for the discerning traveller",
  //   annotation: "domestic",
  //   footerSubtitle:
  //     "Step into a world where each corner whispers a rich tapestry of heritage and culture. From architectural marvels to intricately designed interiors, Lohono villas in Udaipur offer a captivating blend of history and artistry, providing a glimpse into the essence of Rajasthan’s cultural heritage. From villas with private pools blending into nature to 5 star amenities like Wi-Fi, housekeeping and villas in Udaipur with complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Rajasthan with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Udaipur.",
  //   seoTags: {
  //     title: "Luxury Villas in Udaipur - Experience Royalty, the Lohono Way",
  //     description:
  //       "Step into a world where each corner whispers a rich tapestry of heritage and culture. From architectural marvels to intricately designed interiors, Lohono villas in Udaipur offer a captivating blend of history and artistry, providing a glimpse into the essence of Rajasthan’s cultural heritage. From villas with private pools blending into nature to 5 star amenities like Wi-Fi, housekeeping and villas in Udaipur with complimentary breakfast, and other meal packages, such that you’ll be able to savour the taste of Rajasthan with our delectable local lip-smacking preparations in-house.",
  //     canonical:
  //       "https://www.lohono.com/locations/india-udaipur?destination=udaipur",
  //     keywords: "",
  //   },
  // },
  default: {
    image: GENERIC_BANNER,
    title: "Finest Luxury Villas with Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    annotation: "domestic",
    footerSubtitle:
      "Lohono Stays specializes in creating and curating luxury holiday homes for rent all over the world. Tied in with our signature hospitality, the endeavour is to offer the most luxurious and comfortable holiday experiences to our guests with our private villas.",
    seoTags: {
      title: "",
      description: "",
      canonical:
        "https://www.lohono.com/locations/india-goa?destination=goa",
      keywords: "",
    },
  },
};