import React, { DialogHTMLAttributes, useMemo } from "react";
import useIsClientMounted from "../../../../utils/is-client-mounted";
import toISODateString from "../../../../utils/to-iso-date-string";
import useClx from "../../../hooks/use-clx";
import CalendarMonth from "./CalendarMonth";
import { DateDecorator } from "./CalendarMonth/calculate-calendar-template";
import useCalendarCursor, { MonthChangeHandler } from "./use-calendar-cursor";
import clxs from "./calendar-dialog.module.css";

interface CalendarDialogProps extends BaseDialogProps {
  open: boolean;
  position?: "left" | "right";
  min?: number;
  max?: number;
  initialDate?: Date;
  monthCount?: 1 | 2 | 3;
  startDate: Date | null;
  endDate: Date | null;
  monthClassName?: string;
  onMonthChange?: MonthChangeHandler;
  onDateDecorate?: DateDecorator;
  setIsDoneClicked?: (value: boolean) => void
}

function CalendarDialog (props: CalendarDialogProps) {
  const {
      className,
      position = "left",
      open,
      min,
      max,
      initialDate,
      startDate,
      endDate,
      monthCount = 1,
      monthClassName,
      onMonthChange,
      onDateDecorate,
      setIsDoneClicked,
      ...dialogProps
    } = props,
    clientMounted = useIsClientMounted(),
    isMobile = useMemo(
      () => {
        if (typeof window === "undefined") {
          return true;
        }

        const { innerWidth, innerHeight } = window;

        const isMobile = innerHeight > innerWidth;

        return isMobile;
      },
      [],
    ),
    responsiveMonthCount = useMemo(
      () => {
        if (isMobile) {
          return 1;
        }

        return monthCount;
      },
      [monthCount, isMobile],
    ),
    { months, handlePrevious, handleNext } =
      useCalendarCursor(
        max,
        min,
        responsiveMonthCount,
        initialDate,
        onMonthChange,
      ),
    ccx = useClx(clxs.container, className);

  if (!clientMounted) {
    return null;
  }

  return (
    <>
      {isMobile && (
        <div
          className={clxs.backdrop}
          data-open={open}
          data-dismiss={true}
          data-component="backdrop"
        >
          &nbsp;
        </div>
      )}
      <dialog
        {...dialogProps}
        open={open}
        className={ccx}
        data-position={position}
        data-component="dialog"
      >
        <div
          className={clxs.transition}
          suppressHydrationWarning={true}
        >
          {months.map(
            (monthDate) => (
              <CalendarMonth
                key={toISODateString(monthDate)}
                monthDate={monthDate}
                className={monthClassName}
                startDate={startDate}
                endDate={endDate}
                onDateDecorate={onDateDecorate}
              />
            ),
          )}
        </div>
        <button
          data-action="previous"
          className={clxs.navigation}
          onClick={handlePrevious}
          type="button"
        >
          ‹
        </button>
        <button
          data-action="next"
          className={clxs.navigation}
          onClick={handleNext}
          type="button"
        >
          ›
        </button>
        <div className={clxs.actions}>
          {/* <button
            type="button"
            className={clxs.action}
            onClick={handleToday}
          >
            Today
          </button> */}
          <button
            type="button"
            className={clxs.action}
            data-action="clear"
          >
            Clear
          </button>
          <button
            type="button"
            className={clxs.action}
            data-dismiss={true}
            onClick={() => (setIsDoneClicked && setIsDoneClicked(true))}
          >
            Done
          </button>
        </div>
      </dialog>
    </>
  );
}

export default CalendarDialog;

export type { CalendarDialogProps };

type BaseDialogProps = Omit<DialogHTMLAttributes<HTMLDialogElement>, "open">;
