import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/use-analytics";
import useIsEH from "../../hooks/use-is-eh";
import Modal, { useShowModal } from "../../hooks/use-modal";
import usePathParams from "../../hooks/use-path-params";
import usePropertyDetail from "../../hooks/use-property-detail";
import useSendRequest from "../../hooks/use-send-request";
import NotFound from "../NotFound";
import Gallery from "./Gallery";
import LohonoBlackLayout from "./LohonoBlackLayout";
import LohonoLayout from "./LohonoLayout";
import { PriceCalculatorContext } from "../../hooks/use-price-calculator";
import { useQueryParamValue, useSetQueryParam } from "../../hooks/use-query-param";
import useReservationService from "../../hooks/use-reservation-service";
import { useSnackbar } from "../../hooks/use-snackbar";
import { useSession, useUser } from "../../hooks/use-session";

function PropertyDetail () {
  const { pathname, search } = useLocation(),
    push = useNavigate(),
    showGalleryModal = useShowModal(GALLERY_MODAL_ID),
    { showSendRequestModal } = useSendRequest(),
    session = useSession(),
    { service: reservationService } = useReservationService(),
    LOHONO_BRAND = 1,
    enqueueSnackbar = useSnackbar(),
    { track } = useAnalytics(),
    user = useUser(),
    destination = usePathParams(PATH, "destination"),
    location = usePathParams(PATH, "location"),
    property_slug = usePathParams(PATH, "property"),
    checkin_date = useQueryParamValue("date", CHECKIN_DATE_KEY),
    checkout_date = useQueryParamValue("date", CHECKOUT_DATE_KEY),
    coupon_code = useQueryParamValue("string", COUPON_CODE_KEY),
    setCouponCode = useSetQueryParam("string", COUPON_CODE_KEY),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    { isEH } = useIsEH(location_slug),
    { propertyDetail } = usePropertyDetail(property_slug, checkin_date, checkout_date),
    isBlack = useMemo(
      () => propertyDetail?.brand === "lohono_black",
      [propertyDetail],
    ),
    handleShowGallery = () => showGalleryModal({ images: propertyDetail?.images ?? [] }),
    handleShare = async () => {
      if (!propertyDetail) {
        return true;
      }

      const url = window.location.href, share = {
        title: propertyDetail.name,
        text: propertyDetail.description || "Luxury Lohono Villa",
        url: url,
      };

      try {
        await window.navigator.share(share);
      } catch (err) {
        await window.navigator.clipboard.writeText(url);
        return false;
      }

      return true;
    },
    handleSendRequest = (values: any = {}, clickedBy: string) => {
      return showSendRequestModal({
        interest: "rental",
        location: location_slug,
        meta: values,
        clickedBy: clickedBy,
      });
    },
  // If coupon code is not present, check for “discount_method” value if its one of gold, silver, platinum. if yes, then send coupon_code in the reqeust.  
  getCouponValueToset = (
    coupon_code?: string,
    discount_method?: string,
  ) => {
    let couponToSet = "";
      if(coupon_code) {
        couponToSet = coupon_code;
      } else {
        couponToSet = (discount_method == "gold" || discount_method == "silver" || discount_method == "platinum") ? discount_method : "";
      }  
      // else {
      //   couponToSet = "NO_AVAIL"
      // }
    return couponToSet;
  },
  prepareSearchValues = (
    urlString: string,
    booking_id: string,
    booking_slugs: string[], 
    isDisplayProtectWidget: boolean, 
    quote_id: string,
    coupon_code?: string,
    discount_method?: string,
    tier?: string,
  ) => {
    // Check if "booking_id=" and "booking_slugs=" exist
    const bookingIdExists = urlString.includes("booking_id=");
    const bookingSlugsExists = urlString.includes("booking_slugs=");
    const refundableBookingFlag = urlString.includes("avail_refundable_booking=");
    const protectQuoteIdExists = urlString.includes("quote_id=");
    const couponCodeExists = urlString.includes("coupon_code=");
    const tierExists = urlString.includes("tier=");
    
    if (bookingIdExists) {
        const valueToReplace = booking_id ? ("&booking_id=" + booking_id) : "";
        // Remove "booking_id=" and its value
        urlString = urlString.replace(/&?booking_id=[^&]*/, valueToReplace);
    } else {
      urlString += "&booking_id=" + booking_id;
    }
    
    if (bookingSlugsExists) {
        const valueToReplace = booking_slugs ? ("&booking_slugs=" + booking_slugs.join("-")) : "";
        // Remove "booking_slugs=" and its value
        urlString = urlString.replace(/&?booking_slugs=[^&]*/, valueToReplace);
    } else {
      urlString += "&booking_slugs=" + booking_slugs.join("-");
    }

    if (refundableBookingFlag) {
      const valueToReplace = isDisplayProtectWidget ? ("&avail_refundable_booking=true") : "";
      // Remove "booking_slugs=" and its value
      urlString = urlString.replace(/&?avail_refundable_booking=[^&]*/, valueToReplace);
    } else {
      urlString += "&avail_refundable_booking=true";
    }

    if (protectQuoteIdExists) {
      const valueToReplace = quote_id ? ("&quote_id=" + quote_id) : "";
      // Remove "quote_id=" and its value
      urlString = urlString.replace(/&?quote_id=[^&]*/, valueToReplace);
    } else {
      urlString += "&quote_id=" + quote_id;
    }

    if (tierExists) {
      const valueToReplace = tier ? ("&tier=" + tier) : "";
      // Remove "quote_id=" and its value
      urlString = urlString.replace(/&?tier=[^&]*/, valueToReplace);
    } else {
      urlString += "&tier=" + tier;
    }

    const couponToSet = getCouponValueToset(coupon_code, discount_method);
    setCouponCode(couponToSet);
    if (couponCodeExists) {
      const valueToReplace = (coupon_code || couponToSet) ? ("&coupon_code=" + couponToSet) : "";
      // Remove "coupon_code=" and its value
      urlString = urlString.replace(/&?coupon_code=[^&]*/, valueToReplace);
    } else {
      urlString += "&coupon_code=" + couponToSet;
    }
    
    // Clean up any leading '&' if necessary
    urlString = urlString.replace(/(\?&|&&)/g, "?").replace(/&$/, "");
    
    return urlString;
  },handleBook = async (values: any) => {
      track("property_detail_book_clicked");

      if (isEH) {
        return handleSendRequest(values,"Contact us");
      }

      const {
        stay = { start: null, end: null },
        occupancy = { adult_count: 0, child_count: 0 },
        config_args = {},
        pre_book_meals = false,
      } = values,
      { start: checkin_date, end: checkout_date } = stay,
      { adult_count, child_count } = occupancy;

      const isMultiConfig = propertyDetail?.view_layout == "multi_config";
      const isDisplayProtectWidget = !isMultiConfig;

      // Send tier level in coupon code if no coupon code is used.
      const tierLevel = user?.loyalty_point_metadata?.tier;
      let isAddTierToCoupon: boolean = false;
      if(tierLevel?.length && (!coupon_code || coupon_code === "NONE")) {
        isAddTierToCoupon = true;
      }

      const { error: e1, response: r1 } = await reservationService.createBooking(
        {
          property_slug: property_slug,
          slug: property_slug,
          checkin_date: checkin_date.toISOString(),
          checkout_date: checkout_date.toISOString(),
          adult_count: adult_count,
          child_count: child_count,
          coupon_code: isAddTierToCoupon ? tierLevel?.toUpperCase() : coupon_code,
          property_configs: config_args,
          brand: LOHONO_BRAND,
          pre_book_meals: pre_book_meals,
          isRefundableOpted: true,
          display_protect_widget: isDisplayProtectWidget,
        },
        session,
      );

    if (e1) {
      enqueueSnackbar(e1.message, "error");
      return;
    }

    if (!r1) {
      enqueueSnackbar("unknown error", "error");
      return;
    }
      const { booking_id, booking_slugs, widget_details, response_coupon_code, discount_method } = r1;

      const {html, quote_id} = widget_details;

      if((!html || !quote_id) && !isMultiConfig) {
        enqueueSnackbar("Something went wrong. Please contact our customer service.", "error");
        return;
      }

      // if(html) {
      //   localStorage.setItem("protect_html", html);
      // }

      if(quote_id) {
        localStorage.setItem("protect_quote_id", quote_id);
      }

      if (!booking_id) {
        enqueueSnackbar("Booking cannot be made due to some reason. Please contact our customer service.", "error");
      }

      const checkoutUrl = `${pathname}/checkout`;

      const tier = user?.loyalty_point_metadata?.tier;
      
      //Remove old booking ids and slugs, quote id and protect flag in case user comes back from the checkout page. 
      const checkoutSearchParam = prepareSearchValues(search,booking_id, booking_slugs, isDisplayProtectWidget, quote_id, response_coupon_code, discount_method, tier);

      return push({
        pathname: checkoutUrl,
        search: checkoutSearchParam,
      });
    };

  if (!propertyDetail) {
    return <NotFound />;
  }

  return (
    <PriceCalculatorContext
      path={PATH}
      id={PRICE_CALCULATOR_ID}
      propertySlugKey={PROPERTY_SLUG_KEY}
      checkinDateKey={CHECKIN_DATE_KEY}
      checkoutDateKey={CHECKOUT_DATE_KEY}
      adultCountKey={ADULT_COUNT_KEY}
      childCountKey={CHILD_COUNT_KEY}
      couponCodeKey={COUPON_CODE_KEY}
      configs={propertyDetail.configs}
      availLoyaltyPointsKey={AVAIL_LOYALTY_POINTS_KEY}
    >
      <>
        {isBlack ? (
          <LohonoBlackLayout
            propertyDetails={propertyDetail}
            onShowGallery={handleShowGallery}
            onShare={handleShare}
          />
        ) : (
          <LohonoLayout
            priceCalculatorId={PRICE_CALCULATOR_ID}
            path={PATH}
            locationSlug={location_slug}
            propertyDetails={propertyDetail}
            onShowGallery={handleShowGallery}
            onShare={handleShare}
            onSendRequest= {() => handleSendRequest(undefined,"Need Help")}
            onBook={handleBook}
            propertySlugKey={PROPERTY_SLUG_KEY}
            checkinDateKey={CHECKIN_DATE_KEY}
            checkoutDateKey={CHECKOUT_DATE_KEY}
            adultCountKey={ADULT_COUNT_KEY}
            childCountKey={CHILD_COUNT_KEY}
            couponCodeKey={COUPON_CODE_KEY}
            availLoyaltyPointsKey={AVAIL_LOYALTY_POINTS_KEY}
            seniorCitizenDescription={propertyDetail.senior_citizen.description}
            seniorCitizenFriendly={propertyDetail.senior_citizen.senior_citizen_friendly}
          />
        )}
        <Modal id={GALLERY_MODAL_ID}>
          <Gallery />
        </Modal>
      </>
    </PriceCalculatorContext>
  );
}

export default PropertyDetail;

const GALLERY_MODAL_ID = "property-gallery-mobile";

const PATH = "/villas/:destination/:location/:property";

const PROPERTY_SLUG_KEY = "property";

const CHECKIN_DATE_KEY = "checkin_date";

const CHECKOUT_DATE_KEY = "checkout_date";

const ADULT_COUNT_KEY = "adult_count";

const CHILD_COUNT_KEY = "child_count";

const COUPON_CODE_KEY = "coupon_code";

const AVAIL_LOYALTY_POINTS_KEY = "avail_loyalty_points";

const PRICE_CALCULATOR_ID = "property-details-price-calculator";