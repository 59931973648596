import React from "react";
import prepareMeta from "../../../utils/prepare-meta";
import useAnalytics from "../../hooks/use-analytics";
import useClx from "../../hooks/use-clx";
import useSendRequestService from "../../hooks/use-send-request-service";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUtm from "../../hooks/use-utm";
import SendRequestForm from "../SendRequestForm/Minimal";
import clxs from "./send-request.module.css";
import { useLocation } from "react-router-dom";

interface SendRequestProps {
  className?: string;
  title?: string;
  subtitle?: string;
  interest?: string;
  brand?: number;
}

function SendRequest (props: SendRequestProps) {
  const { service } = useSendRequestService(),
    { utm } = useUtm(),
    { track } = useAnalytics(),
    { pathname } = useLocation(),
    enqueueSnackbar = useSnackbar(),
    {
      title = "Need help?",
      subtitle = "Please share your name and number so our team can connect with you",
      interest = "rental",
      brand = 1,
      className,
    } = props,
    ccx = useClx(clxs.sendRequest, className),
    nhcx = useClx(clxs.needHelp, "need-help"),
    nhscx = useClx(clxs.needHelpSubtitle, "need-help-subtitle"),
    scx = useClx(clxs.submit, "submit"),
    isEvent = interest === "events_and_experience" ,
    handleSendRequest = async (values: any) => {
      const {
          full_name,
          phone_ext,
          phone_no,
        } = values,
        phone = `${phone_ext}${phone_no}`,
        { error, response } = await service.save(
          brand,
          full_name,
          phone_ext,
          phone_no,
          "",
          interest,
          "",
          "",
          prepareMeta(utm),
        );

      if (error) {
        enqueueSnackbar(error.message, "error");

        track(
          "send_request_error",
          {
            phone_no: phone,
            error: error.message,
          },
        );
        return;
      }

      if (!response) {
        enqueueSnackbar("Something went wrong", "error");

        track(
          "send_request_error",
          {
            phone_no: phone,
            error: "unknown error",
          },
        );
        return;
      }

      //Old Send Request form event.
      track(
        "send_request_submitted",
        {
          interest: interest,
          phone_no: phone,
          ...response,
        },
      );


      const form_tracking_payload = {
        full_name: full_name,
        location: location,
        country_code: phone_ext,
        mobile: phone_no,
        interested_in: interest,
        // message: message,
        area: pathname,
        button: "Need help",
      }

      //New Send Request form event.
      track(
        "form_submit",
        form_tracking_payload,
      )

      enqueueSnackbar(
        "Thank you for contacting us. We will get in touch with you soon",
        "success",
      );
    };

  return (
    <SendRequestForm
      id="send-request-need-help"
      className={ccx}
      titleNode={(
        <>
          <div className={isEvent ? clxs.needHelpTitle : nhcx}>
            {title}
          </div>
          <div className={isEvent ? clxs.subTitle : nhscx}>
            {subtitle}
          </div>
        </>
      )}
      onSubmit={handleSendRequest}
      isEventPage = {isEvent}
    >
      <button className={isEvent ? clxs.needHelpButton : scx}>
        Submit
      </button>
    </SendRequestForm>
  );
}

export default SendRequest;
