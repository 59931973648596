import format from "date-fns/format";
import React, { ChangeEvent,useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import urlSearchParamsFromAny from "../../../../utils/url-search-params-from-any";
import useClx from "../../../hooks/use-clx";
// import useIsEH from "../../../hooks/use-is-eh";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import usePathParams from "../../../hooks/use-path-params";
import usePropertyLocations from "../../../hooks/use-property-locations";
import { useQueryParamValue, useQuerySearch } from "../../../hooks/use-query-param";
import useToggle from "../../../hooks/use-toggle";
import Button from "../../Button";
import DateRangeInput from "../../DateRangeInput";
import { DRAWER_ID } from "../../Header";
import OccupancySelect from "../../Occupancy/Select";
import PropertySearchMobile from "../../PropertySearchMobile";
import Select from "../../Select";
import Sort from "../Sort/Mobile";
import { BANNER_IMAGE_MAP, BANNER_TITLE_MAP } from "./banner-template";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./property-search.module.css";
import MENU_ICON from "../../../assets/icons/menu.svg";
import LOCATION_INPUT_PIN_ICON from "../../../assets/icons/location-input-pin.svg";
import CHEVRON_DOWN_LIGHT from "../../../assets/icons/chevron-down-light.svg";
import CHEVRON_LEFT_ICON from "../../../assets/icons/chevron-left-grey.svg";
import EVENT_ICON from "../../../assets/calendar-icon.svg";
import ADD_GUESTS_ICON from "../../../assets/occupancy-icon.svg";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";
// import CLOCK_ICON from "../../../assets/limited-time-deal-clock-icon.svg";

interface PropertySearchProps {
  path: string;
  sort: string;
  onSortChange: (nextDirection: string) => void;
  className?: string;
  filterDesktopClassName?: string;
  filterMobileClassName?: string;
  propertyCount: number;
}

function PropertySearch(props: PropertySearchProps) {
  const [search, setQuerySearch] = useQuerySearch(),
    push = useNavigate(),
    showPropertySearchModal = useShowModal(PROPERTY_SEARCH_MODAL_ID),
    { handleToggle } = useToggle(DRAWER_ID),
    { propertyLocations } = usePropertyLocations(),
    locationOptions = useMemo(
      () => propertyLocations.reduce(
        (compiled, each) => {
          const { name, slug, coming_soon } = each;
          if (coming_soon) {
            return compiled;
          }

          const option = {
            label: name,
            value: slug,
          };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string }[],
      ),
      [propertyLocations],
    ),
    {
      path,
      className,
      filterDesktopClassName,
      filterMobileClassName,
      propertyCount,
      sort,
      onSortChange,
    } = props,
    destination = usePathParams(path, "destination"),
    {
      setValue: setDestination,
    } = useStorageString("sessionStorage", "destination"),{
      setValue: setCheckinDate,
    } = useStorageDate("sessionStorage", "checkin_date"), {
      setValue: setCheckoutDate,
    } = useStorageDate("sessionStorage", "checkout_date"), {
      setValue: setAdultCount,
    } = useStorageNumber("sessionStorage", "adult_count"), {
      setValue: setChildCount,
    } = useStorageNumber("sessionStorage", "child_count"),
    location = usePathParams(path, "location"),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    // { isEH } = useIsEH(location_slug),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    userSearch = useQueryParamValue("string", "search"),
    [searchText, setSearchText] = useState<string>(() => userSearch),
    banner = useMemo(
      () => BANNER_IMAGE_MAP[location_slug] || BANNER_IMAGE_MAP.default,
      [location_slug],
    ),
    bannerTitle = useMemo(
      () => BANNER_TITLE_MAP[location_slug] || BANNER_TITLE_MAP.default,
      [location_slug],
    ),
    occupancy = useMemo(
      () => {
        return {
          adult_count: adult_count,
          child_count: child_count,
        };
      },
      [adult_count, child_count],
    ),
    destinationFriendly = useMemo(
      () => {
        const found = locationOptions.find(each => each.value === location_slug);

        return found?.label ?? "";
      },
      [locationOptions, location_slug],
    ),
    stayFriendly = useMemo(
      () => {
        const friendly: string[] = [];

        if (checkin_date) {
          friendly.push(
            format(checkin_date, "dd/MM/yy"),
          );
        }

        if (checkout_date) {
          friendly.push(
            format(checkout_date, "dd/MM/yy"),
          );
        }

        return friendly.join(" - ");
      },
      [checkin_date, checkout_date],
    ),
    guestsFriendly = useMemo(
      () => {
        let totalGuests = 0;

        if (adult_count) {
          totalGuests += adult_count;
        }

        if (child_count) {
          totalGuests += child_count;
        }

        if (!totalGuests) {
          return "";
        }

        return `${totalGuests} guests`;
      },
      [adult_count, child_count],
    ),
    defaultValue = useMemo(
      () => {
        return {
          destination: location_slug,
          stay: {
            start: checkin_date,
            end: checkout_date,
          },
          occupancy: {
            adult_count: adult_count,
            child_count: child_count,
          },
          price_package: "",
        };
      },
      [
        location_slug,
        checkin_date, checkout_date,
        adult_count, child_count,
      ],
    ),
    subtitle = useMemo(
      () => `Found ${propertyCount} luxury villas in ${destinationFriendly}`,
      [destinationFriendly, propertyCount],
    ),
    mhcx = useClx(fwclxs.container, clxs.mobileHeader, filterMobileClassName),
    bcx = useClx(fwclxs.container, clxs.container, className),
    fdcx = useClx(clxs.filters, clxs.filtersDesktop, filterDesktopClassName),
    cmx = useClx(clxs.mobileContainer, filterMobileClassName),
    fmcx = useClx(clxs.filters, clxs.filtersMobile),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    [showDateOnScroll, setShowDateOnScroll] = useState<boolean>(false),
    [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
    [startDate, setStartDate] = useState<Date>(),
    [endDate, setEndDate] = useState<Date>(),
    // wocx = useClx(fwclxs.container, clxs.webOffer),
    reOpenDailog=()=>{
      setShowDateDialog(false);
    },
    handleDestinationChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target,
        destination = target.value,
        url = getPropertySearchUrl(destination),
        params = new URLSearchParams(search as unknown as Record<string, string>);

        if(destination){

          //If Check in date not present auto invoke the date selector.
          if(!checkin_date) {
            setShowDateOnScroll(true)
            setShowDateDialog(true);
          }

          setDestination(destination);
          return push({ pathname: url, search: params.toString() });
        }
    },
    handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { start: checkin_date, end: checkout_date } = target.value as any,
        value = {
          checkin_date: [checkin_date?.toISOString()],
          checkout_date: [checkout_date?.toISOString()],
        };

        setStartDate(checkin_date);
        setEndDate(checkout_date);

        if(checkin_date && checkout_date) { 
          setShowOccupancyDialog(true);
        }

      if(!adult_count) { 
        setShowOccupancyDialog(true);
      }

      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);

      return setQuerySearch(
        (params) => ({ ...params, ...value }),
      );
    },
    handleOccupancyChange = (e: ChangeEvent<any>) => {
      const target = e.target,
        { adult_count, child_count } = target.value,
        value = {
          adult_count: [adult_count],
          child_count: [child_count],
        };

        setAdultCount(adult_count);
        setChildCount(child_count);

      setQuerySearch(
        (params) => ({ ...params, ...value }),
      );
    },
    handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target,
        { value } = target;

      setSearchText(value);
    },
    handleSearch = () => {
      setQuerySearch(
        (params) => ({ ...params, search: [searchText] }),
      );
    },
    handleChange = async () => {
      const values: any = await showPropertySearchModal(
        { defaultValue: defaultValue },
      );

      if (!values) {
        return;
      }

      const {
        destination,
        stay = { start: null, end: null },
        occupancy = { adult_count: 0, child_count: 0 },
        price_package = "",
      } = values,
        path = getPropertySearchUrl(destination),
        { start: checkin_date, end: checkout_date } = stay,
        { adult_count, child_count } = occupancy,
        search = urlSearchParamsFromAny({
          checkin_date: checkin_date,
          checkout_date: checkout_date,
          adult_count: adult_count,
          child_count: child_count,
          price_package: price_package,
        }).toString();

      return push({ pathname: path, search: search });
    },
    handleDoneClick = ()=>{
      if(!startDate){
        setShowDateOnScroll(true);
        setShowDateDialog(true);
      }

      if(!endDate){
        setShowDateOnScroll(true);
        setShowDateDialog(true);
      }
    }
    ;

  useEffect(() =>{ 
    setSearchText(userSearch), [userSearch]

    if(!checkin_date && !checkout_date){
      setShowDateOnScroll(true)
      setShowDateDialog(true);     
    }
    
  },[checkin_date,checkout_date],
);
  
  return (
    <>
      <div className={mhcx}>
        <img
          src={MENU_ICON}
          alt="menu"
          className={clxs.mobileMenu}
          onClick={handleToggle}
        />
        <div
          className={clxs.mobileHeaderContent}
          onClick={handleChange}
        >
          <a
            href="/"
            className={clxs.leftChevron}
          >
            <img
              src={CHEVRON_LEFT_ICON}
              alt="back"
            />
          </a>
          <div className={clxs.mobileHeaderContentIcon}>
            <img
              className={clxs.locationIcon}
              src={LOCATION_INPUT_PIN_ICON}
              alt="location"
            />
            {destinationFriendly}
            <img
              className={clxs.caretIcon}
              src={CHEVRON_DOWN_LIGHT}
              alt="expand"
            />
          </div>
        </div>
        <div className={cmx}>
          <div
            className={fmcx}
            onClick={handleChange}
          >
            <div
              className={clxs.friendly}
              data-populated={Boolean(stayFriendly).valueOf()}
            >
              <img
                className={clxs.eventIcon}
                src={EVENT_ICON}
                alt="eventIcon"
              />
              {stayFriendly || "Check In - Check out"}
            </div>
            <div
              className={clxs.friendly}
              data-populated={Boolean(guestsFriendly).valueOf()}
            >
              <img
                className={clxs.addGuestsIcon}
                src={ADD_GUESTS_ICON}
                alt="addGuestsIcon"
              />
              {guestsFriendly || "Add guests"}
            </div>
          </div>
          <Sort
            className={clxs.sortButton}
            value={sort}
            onChange={onSortChange}
          />
        </div>
      </div>
      <div className={bcx}>
        <img
          loading="lazy"
          src={banner}
          alt="banner"
          className={clxs.background}
        />
        <div className={clxs.overlay}>&nbsp;</div>
        {/* {!isEH ? (
          <div className={wocx}>
            <img
              src={CLOCK_ICON}
              alt="clock_icon"
              className={clxs.clockIcon}
            />
            <div className={clxs.webOfferTitle}>
              {TEMP_BANNER_TITLE}
              <div className={clxs.webOfferSpan}>
                {TEMP_BANNER_SUBTITLE1}
              </div>
            </div>
            <div className={clxs.webOfferSubtitle}>
              {TEMP_BANNER_SUBTITLE2}
            </div>
          </div>
        ) : (
          <>
            <div className={clxs.title}>
              {bannerTitle}
            </div>
            <h1 className={clxs.subtitle}>
              {subtitle}
            </h1>
          </>
        )
        } */}
          <div className={clxs.title}>
            {bannerTitle}
          </div>
          <h1 className={clxs.subtitle}>
            {subtitle}
          </h1>
      </div>
      <div className={fdcx}>
        <Select
          options={locationOptions}
          value={location_slug}
          className={clxs.destination}
          placeholder="Select your Destination*"
          defaultValue=""
          onChange={handleDestinationChange}
        />
        <DateRangeInput
          className={clxs.stay}
          start={checkin_date}
          end={checkout_date}
          onChange={handleStayChange}
          placeholder={["Check In", "Check Out"]}
          onOpenDialog={reOpenDailog}
          calendarDialogProps={{ monthCount: 2 }}
          isShowDialog={showDateDialog}
          isShowDateOnScroll={showDateOnScroll}
          onSelectClicked={() => {setShowDateOnScroll(true)}}
        />
        <OccupancySelect
          value={occupancy}
          className={clxs.occupancy}
          onChange={handleOccupancyChange}
          isShowOccupancyDialog={showOccupancyDialog}
          occupancySuccessText="Apply & Search"
          handleDoneClick={handleDoneClick}
        />
        <div className={clxs.search}>
          <input
            type="text"
            placeholder="🔍 Search by name"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        <Button
          className={clxs.searchButton}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
      <Modal
        id={PROPERTY_SEARCH_MODAL_ID}
        scrollBlock={false}
      >
        <PropertySearchMobile
          viewLayout="standard"
          formId={PROPERTY_SEARCH_MOBILE_FORM_ID}
          hidden={HIDDEN_FIELDS}
          maxAdultCount={24}
          maxChildCount={8}
          configs={[]}
        />
      </Modal>
    </>
  );
}

export default PropertySearch;

const PROPERTY_SEARCH_MODAL_ID = "property-search-modal";

const PROPERTY_SEARCH_MOBILE_FORM_ID = "property-search-mobile";

const HIDDEN_FIELDS: any = ["price_package"];

// const TEMP_BANNER_TITLE = "Grab your Year End Deal Today!";

// const TEMP_BANNER_SUBTITLE1 = "Book before 31st December and get 15% off on your next stay";

// const TEMP_BANNER_SUBTITLE2 = "* T&C Applied";